import React from "react";
import Home from "../pages/Home";
import History from "../pages/History";
import Mypage from "../pages/Mypage";
import TermsOfUse from "../pages/TermsOfUse";
import AppLayout from "../components/layout/AppLayout";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import SignIn from "../pages/SignIn";
import Shared from "../pages/Shared";
import UserList from "../pages/UserList";
import HistoryDetail from "../pages/HistoryDetail";
import ScrollToTop from "../components/common/ScrollToTop";
import Stripe from "../components/common/Stripe";
import { AnimatePresence } from "framer-motion";

function PrivateRoute({ element }: { element: React.ReactNode }) {
  const [user, loading, error] = useAuthState(auth);

  if (error) {
    return (
      <div>
        <p>Error: {error.message}</p>
      </div>
    );
  }

  if (loading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }

  if (user) {
    return element;
  } else {
    return <SignIn />;
  }
}

function Main() {
  return (
    <AnimatePresence>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<AppLayout />}>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route
              path="/:id"
              element={<PrivateRoute element={<Shared />} />}
            />
            <Route path="/home" element={<Home />} />
            <Route
              path="/history"
              element={<PrivateRoute element={<History />} />}
            />
            <Route
              path="/detail"
              element={<PrivateRoute element={<HistoryDetail />} />}
            />
            <Route
              path="/mypage"
              element={<PrivateRoute element={<Mypage />} />}
            />
            <Route
              path="/userlist"
              element={<PrivateRoute element={<UserList />} />}
            />
            <Route
              path="/payment"
              element={<PrivateRoute element={<Stripe />} />}
            />
            <Route path="/termsofuse" element={<TermsOfUse />} />
            <Route path="*" element={<Navigate to="/home" />} />
          </Route>
        </Routes>
      </Router>
    </AnimatePresence>
  );
}

export default Main;
