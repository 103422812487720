import React, { useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useSignInWithGoogle } from "react-firebase-hooks/auth";
import { auth, collection, db, getDocs, query, where } from "../firebase";
import { Navigate, useNavigate } from "react-router-dom";
import { addDoc, serverTimestamp } from "firebase/firestore";

let isInitialAuth = true;

export default function SignIn() {
  const [signInWithGoogle] = useSignInWithGoogle(auth);
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  const handleSignInWithGoogle = async () => {
    setLoading(true);
    await signInWithGoogle();
  };

  auth.onAuthStateChanged(async (user) => {
    if (user && isInitialAuth) {
      isInitialAuth = false;

      const usersCollectionRef = collection(db, "users");
      const userQuery = query(usersCollectionRef, where("uid", "==", user.uid));
      const userSnapshot = await getDocs(userQuery);

      if (userSnapshot.empty) {
        // ユーザーがusersコレクションに存在しない場合は追加する
        await addDoc(collection(db, "users"), {
          uid: user.uid,
          photoURL: user.photoURL,
          displayName: user.displayName,
          createdAt: serverTimestamp(),
          sharedUsers: ["M6WEcVhkBGeS8Ot93HXJGPoSVNT2"],
        });
      }
      setLoggedIn(true);
    }

    // ユーザーがログアウトした場合に再度初期状態にする
    if (!user) {
      isInitialAuth = true;
    }
  });

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // ログインが成功したらリダイレクトする
  if (loggedIn) {
    return <Navigate to="/" />;
  }

  const handleTermsOfUse = () => {
    navigate("/TermsOfUse");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "50px auto",
        width: "75%",
        padding: "0 16px",
        marginTop: "30px",
        height: "50vh",
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        ログイン
      </Typography>

      <Button
        variant="contained"
        color="secondary"
        sx={{
          marginTop: 1,
          color: "white",
          width: "100%",
          height: 60,
          fontSize: 21,
          borderRadius: 10,
          textTransform: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={handleSignInWithGoogle}
        startIcon={<GoogleIcon sx={{ height: 30, width: 30 }} />}
      >
        Googleでログイン
      </Button>

      {/* <Button
        variant="contained"
        color="secondary"
        sx={{
          marginTop: 1,
          color: "white",
          width: "100%",
          height: 60,
          fontSize: 21,
          borderRadius: 10,
          textTransform: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        startIcon={<FacebookIcon sx={{ height: 30, width: 30 }} />}
      >
        Facebookでログイン
      </Button> */}

      <Typography variant="subtitle2" sx={{ marginTop: 2 }} gutterBottom>
        新規登録、ログインのどちらも上記のリンクから行うことができます。
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        <Button sx={{ fontSize: 15 }} onClick={handleTermsOfUse}>
          利用規約
        </Button>
        に同意したうえでログインして下さい。
      </Typography>
    </Box>
  );
}
