import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { auth, collection, db, getDocs, query, where } from "../firebase";
import { useNavigate } from "react-router-dom";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import { Timestamp, orderBy } from "firebase/firestore";
import { QRCodeSVG } from "qrcode.react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Transition from "../utils/Transition";

type History = {
  id: string;
  stationName: string;
  targetStationName: string;
  createdAt: Timestamp;
  memo: string;
  uid: string;
};

type User = {
  id: string;
  displayName: string;
  createdAt: Timestamp;
  uid: string;
};

export default function Mypage() {
  const navigate = useNavigate();
  const [monthlyHistoryCount, setMonthlyHistoryCount] = useState(0);
  const [totalHistoryCount, setTotalHistoryCount] = useState(0);
  const [latestTargetStationName, setLatestTargetStationName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [signOutDialog, setSignOutDialog] = useState(false);
  const [qrCodeDialog, setQrCodeDialog] = useState(false);
  const [lastHistoryDate, setLastHistoryDate] = useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState("");

  const handleTermsOfUse = () => {
    navigate("/termsofuse");
  };

  const handleUserList = () => {
    navigate("/userlist");
  };

  const handlePayment = () => {
    navigate("/payment");
  };

  const handleSignOut = async () => {
    await auth.signOut();
  };

  const fetchHistory = async () => {
    setIsLoading(true);
    try {
      const historiesCollectionRef = collection(db, "histories");
      const q = query(
        historiesCollectionRef,
        where("uid", "==", auth.currentUser.uid),
        orderBy("createdAt", "desc")
      );
      const querySnapshot = await getDocs(q);
      const historyData: History[] = [];
      querySnapshot.forEach((doc) => {
        historyData.push({ id: doc.id, ...doc.data() } as History);
      });

      // 今月の件数をカウント
      const now = new Date();
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      const monthlyHistoryCount = historyData.filter((history) => {
        const createdAt = new Date(history.createdAt.toDate());
        return createdAt >= startOfMonth && createdAt <= endOfMonth;
      }).length;

      // Historyの合計の件数をカウント
      const totalHistoryCount = historyData.length;

      //
      // ユーザーの情報を取得するクエリを作成
      const usersCollectionRef = collection(db, "users");
      const userQuery = query(
        usersCollectionRef,
        where("uid", "==", auth.currentUser.uid)
      );
      const userSnapshot = await getDocs(userQuery);
      const userData: User[] = [];
      userSnapshot.forEach((doc) => {
        userData.push({ id: doc.id, ...doc.data() } as User);
      });
      userData.forEach((user) => {
        setQrCodeUrl(user.id);
      });

      // 最新のHistoryのtargetStationNameを取得
      const latestHistory = historyData.reduce((latest, current) => {
        if (
          !latest ||
          new Date(current.createdAt.toDate()) >
            new Date(latest.createdAt.toDate())
        ) {
          return current;
        }
        return latest;
      }, null);

      setMonthlyHistoryCount(monthlyHistoryCount);
      setTotalHistoryCount(totalHistoryCount);
      setLatestTargetStationName(
        latestHistory ? latestHistory.targetStationName : ""
      );
      setLastHistoryDate(
        latestHistory
          ? latestHistory.createdAt.toDate().toLocaleDateString()
          : ""
      );
    } catch (error) {
      console.error("ヒストリーの取得中にエラーが発生しました:", error);
    } finally {
      // データ取得後にisLoadingをfalseに設定
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  // isLoadingがtrueの間はコンポーネントを非表示にする
  if (isLoading) {
    return null;
  }

  const handleSignOutCancel = () => {
    setSignOutDialog(false);
  };

  const handleQrCodeCancel = () => {
    setQrCodeDialog(false);
  };

  const copyToClipboard = async () => {
    const clipboard = (navigator as Navigator).clipboard;
    const sharedUrl = "https://tetsubura.com/shared?url=" + qrCodeUrl;
    if (clipboard) {
      await clipboard.writeText(sharedUrl);
    } else {
      console.error("クリップボードが利用できません");
    }
  };

  return (
    <Transition>
      <Box sx={{ minWidth: 275 }}>
        <CardContent sx={{ textAlign: "center" }}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            <img src={auth.currentUser.photoURL} style={{ borderRadius: 50 }} />
          </Typography>
          <Typography variant="h5" component="div">
            {auth.currentUser.displayName}
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: "center", alignItems: "center" }}>
          <Button sx={{ fontSize: 18 }} onClick={handleTermsOfUse}>
            利用規約
          </Button>
          <Button sx={{ fontSize: 18 }} onClick={() => setSignOutDialog(true)}>
            サインアウト
          </Button>
        </CardActions>
        <Divider></Divider>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            flexWrap: "wrap",
            mt: 2,
          }}
        >
          <Box>
            <Chip label="直近の駅" size="small" />
            <CardContent sx={{ textAlign: "center" }}>
              <Typography variant="h6" component="div">
                {latestTargetStationName}駅
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {lastHistoryDate}
              </Typography>
            </CardContent>
          </Box>

          <Box>
            <Chip label="今月の旅" size="small" />
            <CardContent sx={{ textAlign: "center" }}>
              <Typography variant="h6" component="div">
                {monthlyHistoryCount}回
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                <br />
              </Typography>
            </CardContent>
          </Box>

          <Box>
            <Chip label="旅の合計" size="small" />
            <CardContent sx={{ textAlign: "center" }}>
              <Typography variant="h6" component="div">
                {totalHistoryCount}回
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                <br />
              </Typography>
            </CardContent>
          </Box>
        </Box>

        <Divider>
          <Chip label="シェア" size="small" />
        </Divider>
        <CardContent sx={{ textAlign: "center" }}>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            友達と旅履歴をシェアしよう
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CardActions
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <Button
                sx={{ fontSize: 15 }}
                onClick={() => setQrCodeDialog(true)}
              >
                シェアする
              </Button>
            </CardActions>
            <CardActions
              sx={{ justifyContent: "center", alignItems: "center" }}
            >
              <Button sx={{ fontSize: 15 }} onClick={handleUserList}>
                友達一覧
              </Button>
            </CardActions>
          </Box>
        </CardContent>
        <Divider></Divider>

        <Dialog
          open={signOutDialog}
          onClose={handleSignOutCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">確認</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              サインアウトしますか？
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSignOutCancel} color="primary">
              キャンセル
            </Button>
            <Button onClick={handleSignOut} color="primary" autoFocus>
              サインアウト
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={qrCodeDialog}
          onClose={handleQrCodeCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">QRコード</DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <QRCodeSVG
              value={"https://tetsubura.com/shared?url=" + qrCodeUrl}
              size={300}
            />
            <Box sx={{ display: "flex", marginTop: 2 }}>
              <Typography>URLをコピー</Typography>
              <Button color="primary" size="small" onClick={copyToClipboard}>
                <ContentCopyIcon fontSize="small" />
              </Button>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleQrCodeCancel} color="primary">
              閉じる
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Transition>
  );
}
