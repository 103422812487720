import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeIcon from "@mui/icons-material/Home";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import SettingsIcon from "@mui/icons-material/Settings";

export default function FixedBottomNavigation() {
  const [value, setValue] = useState(0);
  const [uid, setUid] = useState<string | null>(auth.currentUser?.uid || null);
  const ref = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const handleNavigation = (url: string) => {
    navigate(url);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUid(user.uid);
      } else {
        setUid(null);
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <CssBaseline />
      <Box
        sx={{
          position: "fixed",
          bottom: "0",
          left: "50%",
          transform: "translateX(-50%)",
          width: "100%",
          height: "80px",
          // overflow: "hidden",
          // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          sx={{ height: "80px" }}
        >
          <BottomNavigationAction
            // label="ホーム"
            icon={<HomeIcon fontSize="large" />}
            onClick={() => handleNavigation("/home")}
          />
          <BottomNavigationAction
            // label="旅の履歴"
            icon={<FormatListBulletedIcon fontSize="large" />}
            onClick={() => handleNavigation("/History")}
          />
          <BottomNavigationAction
            // label="マイページ"
            icon={
              uid ? (
                <img
                  src={auth.currentUser.photoURL}
                  style={{ borderRadius: 50, width: 28 }}
                  alt="user-icon"
                />
              ) : (
                <SettingsIcon fontSize="large" />
              )
            }
            onClick={() => handleNavigation("/mypage")}
          />
        </BottomNavigation>
      </Box>
      {/* <Paper
        // sx={{ position: "fixed", bottom: 0, left: 0, right: 0, height: 60 }}
        sx={{
          position: "fixed",
          justifyContent: "space-around",
          alignItems: "center",
          bottom: "8px",
          left: "50%",
          transform: "translateX(-50%)",
          borderRadius: "20px",
          width: "98%",
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          // color="secondary"
        >
          <BottomNavigationAction
            // label="ホーム"
            icon={<HomeIcon fontSize="large" />}
            onClick={() => handleNavigation("/home")}
          />
          <BottomNavigationAction
            // label="旅の記録"
            icon={<FormatListBulletedIcon fontSize="large" />}
            onClick={() => handleNavigation("/History")}
          />
          <BottomNavigationAction
            // label="マイページ"
            icon={
              uid ? (
                <img
                  src={auth.currentUser.photoURL}
                  style={{ borderRadius: 50, width: 30 }}
                  alt="user-icon"
                />
              ) : (
                <SettingsIcon fontSize="large" />
              )
            }
            onClick={() => handleNavigation("/mypage")}
          />
        </BottomNavigation>
      </Paper> */}
    </Box>
  );
}
