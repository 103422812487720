import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
  TextField,
} from "@mui/material";
import { stations } from "../data/stations";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { auth, db } from "../firebase";
import DiceScene from "../components/dice/DiceScene";
export type AnimateState = "rest" | "rolling" | "drop";
import { startTransition } from "react";
import { fetchSharedUsers } from "../data/firestore";
import Transition from "../utils/Transition";

type User = {
  id: string;
  displayName: string;
  uid: string;
  photoURL: string;
};

export default function Home() {
  const [upDown, setUpDown] = useState("3");
  const [station, setStation] = useState("39");
  const [randomNumber, setRandomNumber] = useState<number | null>(null);
  const [targetStationName, setTargetStationName] = useState("");
  const [loading, setLoading] = useState(false);
  const [trainSchedule, setTrainSchedule] = useState("");
  const [stationName, setStationName] = useState("");
  const [uid, setUid] = useState<string | null>(auth.currentUser?.uid || null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openBoardDialog, setOpenBoardDialog] = useState(false);
  const [photoURL, setPhotoURL] = useState("");
  const [displayName, setDisplayName] = useState("");

  const [sum, setSum] = useState<number>(0);
  const [diceValue, setDiceValue] = useState<number>(1);
  const [animateState, setAnimateState] = useState<AnimateState>("rest");

  const [diceValues, setDiceValues] = useState<number[]>([1]);
  const [diceNumber, setDiceNumber] = useState("1");

  const [sharedUsers, setSharedUsers] = useState<User[]>([]);

  const [selectUser, setSelectUser] = useState<string>("");

  const userChange = (event: SelectChangeEvent) => {
    setSelectUser(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const userList = await fetchSharedUsers(auth.currentUser.uid);
      setSharedUsers(userList);
    };

    const checkAuthState = () => {
      if (auth.currentUser) {
        fetchData();
        setUid(auth.currentUser.uid);
      }
    };
    // コンポーネントがマウントされた時にログイン状態を確認する
    checkAuthState();
    // auth.currentUserが変化した時に再度ログイン状態を確認する
    auth.onAuthStateChanged(checkAuthState);
  }, []);

  const upDownChange = (event: SelectChangeEvent) => {
    setUpDown(event.target.value);
  };

  const stationChange = (event: SelectChangeEvent) => {
    setStation(event.target.value);
  };

  const diceNumberChange = (event: SelectChangeEvent) => {
    // 最大駅数ではなく、サイコロの数を変更する関数に変更
    setDiceNumber(event.target.value);
    setDiceValues([1]); // サイコロの数が変更されたら、サイコロの値を初期化する
  };

  const handleExecute = async () => {
    startTransition(() => {
      setLoading(true); // この行をstartTransition内に移動
    });

    startTransition(() => {
      // 以下の処理は変更箇所です
      // 非同期処理をstartTransitionでラップする
      // const max = parseInt(number);
      // const random = Math.floor(Math.random() * max) + 1;

      const max = parseInt(diceNumber); // サイコロの数を取得
      const randomValues = Array.from(
        { length: max },
        () => Math.floor(Math.random() * 6) + 1
      ); // サイコロの数分だけランダムな値を生成
      setDiceValues(randomValues); // サイコロの値をセット

      // ランダムが選択された場合ランダムで 1 or 2に変換する
      const targetUpDown =
        parseInt(upDown) === 3
          ? String(Math.floor(Math.random() * 2) + 1)
          : upDown;
      const randomValuesSum = randomValues.reduce(
        (acc, value) => acc + value,
        0
      );
      // 駅番号を取得
      const targetStationNumber =
        parseInt(targetUpDown) === 1
          ? parseInt(station) - randomValuesSum
          : parseInt(station) + randomValuesSum;
      const targetStation = stations.find(
        (station) => station.value === targetStationNumber
      );

      // 最新の時刻表を取得
      const departureStationNumber = station;
      const departureStation = stations.find(
        (station) => station.value === Number(departureStationNumber)
      );
      setStationName(departureStation.label);

      // 乗車時間のURLを取得
      const trainScheduleUrl =
        "https://www.jorudan.co.jp/norikae/cgi/nori.cgi?rf=top&eok1=R-&eok2=R-&pg=0&eki1=" +
        departureStation.label +
        "&Cmap1=&eki2=" +
        targetStation.label +
        "&Dyy=2024&S=検索&Csg=1";

      // サイコロのアニメーションを実行する
      setAnimateState("rolling");
      setTimeout(() => setAnimateState("drop"), 1000);
      setTimeout(() => setAnimateState("rest"), 1500);

      setTimeout(() => {
        // setLoading(false);
        setRandomNumber(randomValuesSum);
        setTargetStationName(targetStation ? targetStation.label : "");
        setTrainSchedule(trainScheduleUrl);
        setPhotoURL(auth.currentUser?.photoURL || "");
        setDisplayName(auth.currentUser?.displayName || "");
        // handleOpenBoardDialog();
      }, 2600);

      // handleOpenBoardDialog();

      // 表示を初期化する
      setRandomNumber(null);
      setTargetStationName("");
      setTrainSchedule("");

      setDiceValue(randomValuesSum);
    });
  };

  // const handleAddHistory = async () => {
  //   try {
  //     const historiesCollectionRef = collection(db, "histories");
  //     const createdAt = serverTimestamp();
  //     const memo = "";
  //     await addDoc(historiesCollectionRef, {
  //       uid,
  //       stationName,
  //       targetStationName,
  //       createdAt,
  //       memo,
  //       trainSchedule,
  //       photoURL,
  //       displayName,
  //     });
  //   } catch (error) {
  //     console.error("Error adding history:", error);
  //   }
  //   setOpenDialog(false);

  //   // 表示を初期化する
  //   setRandomNumber(null);
  //   setTargetStationName("");
  //   setTrainSchedule("");
  //   setLoading(false);
  // };

  const handleAddHistory = async () => {
    try {
      // Add history to your own list
      const historiesCollectionRef = collection(db, "histories");
      const createdAt = serverTimestamp();
      const memo = "";
      await addDoc(historiesCollectionRef, {
        uid,
        stationName,
        targetStationName,
        createdAt,
        memo,
        trainSchedule,
        photoURL,
        displayName,
      });

      // Add history to selected users' lists
      const selectedUsers = selectUser ? selectUser.split(",") : [];
      selectedUsers.forEach(async (selectedUid) => {
        await addDoc(historiesCollectionRef, {
          uid: selectedUid,
          stationName,
          targetStationName,
          createdAt,
          memo,
          trainSchedule,
          photoURL,
          displayName,
        });
      });
    } catch (error) {
      console.error("Error adding history:", error);
    }
    setOpenDialog(false);

    // Reset values
    setRandomNumber(null);
    setTargetStationName("");
    setTrainSchedule("");
    setLoading(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleOpenBoardDialog = () => {
    setOpenBoardDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenBoardDialog(false);
  };

  const animate = async (newSum: number) => {
    setAnimateState("rolling");
    await sleep(1000);
    setAnimateState("drop");
    await sleep(500);
    setAnimateState("rest");

    setSum(newSum);
  };

  const onClickDice = () => {
    const newDiceValue = Math.floor(Math.random() * 6) + 1;
    const newSum = sum + newDiceValue;

    setDiceValue(newDiceValue);

    return { newDiceValue, newSum };
  };

  return (
    <Transition>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "50px auto",
          width: "95%",
          padding: "0 16px",
          marginTop: "30px",
        }}
      >
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="up-down-select-label">方面</InputLabel>
          <Select
            labelId="up-down-select-label"
            id="up-down-select"
            value={upDown}
            label="方面"
            onChange={upDownChange}
            sx={{ width: "100%" }}
          >
            <MenuItem value={"1"}>上り</MenuItem>
            <MenuItem value={"2"}>下り</MenuItem>
            <MenuItem value={"3"}>ランダム</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="station-select-label">出発駅</InputLabel>
          <Select
            labelId="station-select-label"
            id="station-select"
            value={station}
            label="出発駅"
            onChange={stationChange}
            sx={{ width: "100%" }}
          >
            {stations.map((station) => (
              <MenuItem key={station.value} value={station.value}>
                {station.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="number-select-label">サイコロの数</InputLabel>
          <Select
            labelId="number-select-label"
            id="number-select"
            value={diceNumber}
            label="サイコロの数"
            onChange={diceNumberChange}
            sx={{ width: "100%" }}
          >
            {[1, 2, 3, 4, 5, 6].map((num) => (
              <MenuItem key={num} value={String(num)}>
                {num}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleExecute}
          sx={{
            // marginTop: 0.5,
            color: "white",
            width: "100%",
            height: 50,
            fontSize: 18,
            borderRadius: 10,
          }}
        >
          目的地を決める
        </Button>

        {targetStationName !== "" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography variant="h5" component="div" sx={{ mb: 2 }}>
              {stationName}駅 → {targetStationName}駅
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                target="_blank"
                href={trainSchedule}
                sx={{ fontSize: "18px", mt: 2, mr: 2 }}
              >
                時刻表を見る
              </Button>
              {uid ? (
                <Button
                  onClick={() => handleOpenDialog()}
                  variant="contained"
                  color="success"
                  sx={{ mt: 2 }}
                >
                  ここに行く
                </Button>
              ) : (
                <></>
              )}
              <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">確認</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {targetStationName}駅 を目的地に登録しますか？
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-description"></DialogContentText>
                  {/* <Autocomplete
                  multiple
                  id="tags-standard"
                  options={sharedUsers.map(user => user.displayName)}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="履歴を追加するユーザーを選択"
                      placeholder="ユーザーを選択"
                    />
                  )}
                /> */}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDialog}>キャンセル</Button>
                  <Button onClick={handleAddHistory} autoFocus>
                    登録
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Box>
        )}

        {loading && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {[0, 3].map((rowStart) => (
              <Box key={rowStart} sx={{ display: "flex" }}>
                {diceValues
                  .slice(rowStart, rowStart + 3)
                  .map((diceValue, index) => (
                    <Box key={index} sx={{ marginRight: 1 }}>
                      <DiceScene
                        diceValue={diceValue}
                        onClick={onClickDice}
                        animateState={animateState}
                        animate={animate}
                      />
                    </Box>
                  ))}
              </Box>
            ))}
          </Box>
        )}

        <Dialog
          open={openBoardDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
        >
          <DialogContent>
            <Button onClick={handleCloseDialog}>
              <DialogContentText id="alert-dialog-description">
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <img
                    style={{ width: "100%" }}
                    src={`/board.png`}
                    alt="Board"
                  />
                  <p
                    style={{
                      position: "absolute",
                      top: "-28%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      fontSize: 100,
                      color: "black",
                    }}
                  >
                    {targetStationName}
                  </p>
                  <p
                    style={{
                      position: "absolute",
                      top: "28%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      fontSize: 40,
                      color: "black",
                    }}
                  >
                    やまなしし
                  </p>

                  <p
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "10%",
                      transform: "translateX(-50%)",
                      fontSize: 40,
                      color: "white",
                    }}
                  >
                    春日居
                  </p>

                  <p
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "80%",
                      transform: "translateX(-50%)",
                      fontSize: 40,
                      color: "white",
                    }}
                  >
                    東山梨
                  </p>
                </Box>
              </DialogContentText>
            </Button>
          </DialogContent>
        </Dialog>
      </Box>
    </Transition>
  );
}

const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
