import { Timestamp, addDoc, collection, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { auth, db } from '../firebase';
import React, { useState } from 'react';
import { stations } from "../data/stations";

// ユーザー情報を取得(自分以外)
export const fetchSharedUsers = async (currentUser: string) => {
  type User = {
    id: string;
    displayName: string;
    uid: string;
    photoURL: string;
  };

  try {
    const usersCollectionRef = collection(db, "users");
    const q = query(
      usersCollectionRef,
      where("sharedUsers", "array-contains", currentUser)
    );
    const querySnapshot = await getDocs(q);
    const sharedUsersData: User[] = [];
    querySnapshot.forEach((doc) => {
      if (doc.data().uid !== currentUser) {
        sharedUsersData.push({ id: doc.id, ...doc.data() } as User);
      }
    });
    return sharedUsersData
  } catch (error) {
    console.error("Error fetching shared users:", error);
  }
};

// シェアしているユーザーの削除
export const DeleteSharedUser = async (deleteId: string) => {
  try {
    // 削除対象のusersコレクションのsharedUsers自分のuidを削除する
    const docRef = doc(db, "users", deleteId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      if (data) {
        // sharedUsersから現在ログインしているユーザーのUIDを削除
        const updatedSharedUsers = data.sharedUsers.filter(
          (uid: string) => uid !== auth.currentUser.uid
        );

        // 更新されたsharedUsersをドキュメントに反映
        await updateDoc(docRef, { sharedUsers: updatedSharedUsers });
      }
    } else {
      console.log("No such document!");
    }

    // 自分のusersから削除する
    // const userDocRef = doc(db, "users", auth.currentUser.uid);
    // const userDocSnapshot = await getDoc(userDocRef);
    // if (userDocSnapshot.exists()) {
    //     const updatedSharedUsers = userDocSnapshot.data()?.sharedUsers.filter((uid: string) => uid !== auth.currentUser.uid);
    //     await updateDoc(userDocRef, { sharedUsers: updatedSharedUsers });
    //     console.log("User deleted successfully!");
    // } else {
    //     console.error("User document not found for deletion");
    // }
    await fetchSharedUsers(auth.currentUser.uid);
  } catch (error) {
      console.error("Error deleting user:", error);
  }
};

// 旅の履歴を取得
export const fetchHistories = async (selectuid: string, searchTerm: string) => {
  type History = {
    id: string;
    stationName: string;
    targetStationName: string;
    createdAt: Timestamp;
    memo: string;
    uid: string;
    photoURL: string;
    displayName: string;
  };

  try {
    const historiesCollectionRef = collection(db, "histories");
    let q = query(
      historiesCollectionRef,
      where("uid", "==", selectuid),
      orderBy("createdAt", "desc")
    );

    if (searchTerm) {
      q = query(
        historiesCollectionRef,
        where("uid", "==", selectuid),
        where("targetStationName", ">=", searchTerm),
        where("targetStationName", "<=", searchTerm + "\uf8ff"),
        orderBy("createdAt", "desc")
      );
    }

    const querySnapshot = await getDocs(q);
    const historyData: History[] = [];
    querySnapshot.forEach((doc) => {
      historyData.push({ id: doc.id, ...doc.data() } as History);
    });
    return historyData;
  } catch (error) {
    console.error("Error fetching histories:", error);
  }
};