import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  ListItemAvatar,
  Avatar,
  Divider,
} from "@mui/material";
import { Timestamp, addDoc, collection } from "firebase/firestore";
import { auth, db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { stations } from "../data/stations";
import AddIcon from "@mui/icons-material/Add";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { fetchSharedUsers } from "../data/firestore";
import { fetchHistories } from "../data/firestore";
import Transition from "../utils/Transition";

type History = {
  id: string;
  stationName: string;
  targetStationName: string;
  createdAt: Timestamp;
  memo: string;
  uid: string;
  photoURL: string;
  displayName: string;
};

type User = {
  id: string;
  displayName: string;
  uid: string;
  photoURL: string;
};

export default function ConfirmationDialog() {
  const navigate = useNavigate();
  const [histories, setHistories] = useState<History[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectUser, setSelectUser] = useState(auth.currentUser.uid);
  const [sharedUsers, setSharedUsers] = useState<User[]>([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());

  useEffect(() => {
    const fetchData = async () => {
      // シェアしているユーザーの取得
      const userList = await fetchSharedUsers(auth.currentUser.uid);
      setSharedUsers(userList);

      // 旅の履歴を取得
      const historyList = await fetchHistories(selectUser, searchTerm);
      setHistories(historyList);
    };

    fetchData();
  }, [searchTerm]);

  // 履歴を追加する関数
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [newHistory, setNewHistory] = useState({
    stationName: "39",
    targetStationName: "39",
    memo: "",
  });

  const addHistory = async () => {
    try {
      const newDocRef = await addDoc(collection(db, "histories"), {
        stationName: stations.find(
          (station) => station.value === Number(newHistory.stationName)
        ).label,
        targetStationName: stations.find(
          (station) => station.value === Number(newHistory.targetStationName)
        ).label,
        memo: newHistory.memo,
        createdAt: selectedDate.toDate(),
        uid: auth.currentUser.uid,
        displayName: auth.currentUser.displayName,
        photoURL: auth.currentUser.photoURL,
      });
      // console.log("Document written with ID: ", newDocRef.id);
      setAddDialogOpen(false);
      // 新しい履歴を取得して表示を更新する
      fetchHistories(selectUser, searchTerm);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const userChange = (event: SelectChangeEvent) => {
    // データの再取得処理
    const fetchData = async () => {
      // 旅の履歴を取得
      const historyList = await fetchHistories(event.target.value, searchTerm);
      setHistories(historyList);
      setSelectUser(event.target.value);
    };
    fetchData();
  };

  const historyDetail = (id: string) => {
    navigate("/detail?id=" + id);
  };

  return (
    <Transition>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <TextField
          label="検索"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ marginBottom: 2, marginTop: 1 }}
          // sx={{ marginBottom: "10px" }}
        />

        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="up-down-select-label">ユーザー選択</InputLabel>
          <Select
            labelId="up-down-select-label"
            id="up-down-select"
            value={selectUser}
            label="ユーザー選択"
            onChange={userChange}
            sx={{ width: "100%" }}
          >
            <MenuItem value={auth.currentUser.uid}>自分の履歴</MenuItem>
            {sharedUsers.map((user) => (
              <MenuItem key={user.id} value={user.uid}>
                {user.displayName} の履歴
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          onClick={() => setAddDialogOpen(true)}
          sx={{ marginBottom: "10px" }}
        >
          履歴追加 <AddIcon />
        </Button>
        <Divider></Divider>

        {/* 履歴追加用のダイアログ */}
        <Dialog
          open={addDialogOpen}
          onClose={() => setAddDialogOpen(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>旅の履歴を追加</DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  defaultValue={selectedDate}
                  format="YYYY/MM/DD"
                  onChange={(date) => setSelectedDate(date || dayjs())}
                />
              </LocalizationProvider>
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="station-select-label">出発駅</InputLabel>
              <Select
                value={newHistory.stationName}
                onChange={(e) =>
                  setNewHistory({
                    ...newHistory,
                    stationName: e.target.value,
                  })
                }
              >
                {stations.map((station) => (
                  <MenuItem key={station.value} value={station.value}>
                    {station.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="station-select-label">到着駅</InputLabel>
              <Select
                value={newHistory.targetStationName}
                onChange={(e) =>
                  setNewHistory({
                    ...newHistory,
                    targetStationName: e.target.value,
                  })
                }
              >
                {stations.map((station) => (
                  <MenuItem key={station.value} value={station.value}>
                    {station.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="メモ"
              fullWidth
              value={newHistory.memo}
              onChange={(e) =>
                setNewHistory({ ...newHistory, memo: e.target.value })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddDialogOpen(false)}>キャンセル</Button>
            <Button onClick={addHistory}>追加</Button>
          </DialogActions>
        </Dialog>

        <List component="div" role="group">
          {histories.map((history) => (
            <ListItemButton
              key={history.id}
              onClick={() => historyDetail(history.id)}
            >
              <ListItem divider>
                <ListItemAvatar
                  style={{ alignSelf: "flex-start", marginTop: "8px" }}
                >
                  <Avatar alt="Profile Picture" src={history.photoURL} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <div style={{ fontSize: "1.1rem" }}>
                        {history.displayName}
                      </div>
                      <div style={{ fontSize: "1.1rem" }}>
                        乗車：{history.stationName}駅 →{" "}
                        {history.targetStationName}駅
                      </div>
                      <div style={{ marginTop: "4px" }}>
                        {history.memo
                          ? history.memo.split("\n").map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))
                          : null}
                      </div>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      {history.createdAt.toDate().toLocaleString("ja-JP", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </React.Fragment>
                  }
                  sx={{ fontSize: "1.1rem" }}
                />
              </ListItem>
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Transition>
  );
}
