import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import {
  Timestamp,
  doc,
  getDoc,
  updateDoc,
  collection,
  addDoc,
  serverTimestamp,
  where,
  orderBy,
  query as firebaseQuery,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ShareIcon from "@mui/icons-material/Share";
import { fetchSharedUsers } from "../data/firestore";
import Transition from "../utils/Transition";

type History = {
  id: string;
  stationName: string;
  targetStationName: string;
  createdAt: Timestamp;
  memo: string;
  uid: string;
  photoURL: string;
  displayName: string;
  likes: string[];
};

type Comment = {
  id: string;
  createdAt: Timestamp;
  displayName: string;
  historyId: string;
  photoURL: string;
  text: string;
  uid: string;
};

type User = {
  id: string;
  displayName: string;
  uid: string;
  photoURL: string;
};

export default function HistoryDetail() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const id = query.get("id");

  const [histories, setHistories] = useState<History[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedHistory, setSelectedHistory] = useState<History | null>(null);
  const [memoText, setMemoText] = useState("");
  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState<Comment[]>([]);

  const [deleteId, setDeleteId] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [openCommentDialog, setOpenCommentDialog] = useState(false);

  const [commentDeleteOpen, setCommentDeleteOpen] = useState(false);
  const [deleteCommentId, setDeleteCommentId] = useState("");

  const [sharedUsers, setSharedUsers] = useState<User[]>([]);

  useEffect(() => {
    if (!id) {
      navigate("/home");
    } else {
      fetchHistoryData(id);
      fetchComments(id).then((unsubscribeComments) => {
        return () => unsubscribeComments();
      });

      const fetchData = async () => {
        const userList = await fetchSharedUsers(auth.currentUser.uid);
        setSharedUsers(userList);
      };

      fetchData();
    }
  }, [id, navigate]);

  const fetchHistoryData = async (docId: string) => {
    try {
      const historyDocRef = doc(db, "histories", docId);
      const docSnap = await getDoc(historyDocRef);
      if (docSnap.exists()) {
        const historyData = { id: docSnap.id, ...docSnap.data() } as History;
        setHistories([historyData]);
      } else {
        console.error("No such document");
      }
    } catch (error) {
      console.error("Error fetching history data:", error);
    }
  };

  const updateMemo = async (id: string, memo: string) => {
    try {
      await updateDoc(doc(db, "histories", id), {
        memo: memo,
      });
      setHistories((prev) =>
        prev.map((history) =>
          history.id === id ? { ...history, memo: memo } : history
        )
      );
    } catch (error) {
      console.error("Error updating memo:", error);
    }
  };

  const fetchComments = async (historyId: string) => {
    try {
      const commentsCollectionRef = collection(db, "comments");
      const q = firebaseQuery(
        commentsCollectionRef,
        where("historyId", "==", historyId),
        orderBy("createdAt", "desc")
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const commentsData: Comment[] = [];
        querySnapshot.forEach((doc) => {
          commentsData.push({ id: doc.id, ...doc.data() } as Comment);
        });
        setComments(commentsData);
      });

      return unsubscribe;
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const handleDeleteClick = (id: string) => {
    setDeleteId(id);
    setDeleteOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteDoc(doc(db, "histories", deleteId));
      setHistories((prev) => prev.filter((history) => history.id !== deleteId));
      navigate("/history");
    } catch (error) {
      console.error("Error deleting history:", error);
    } finally {
      setDeleteId("");
      setDeleteOpen(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteId("");
    setDeleteOpen(false);
  };

  const handleCommentClick = () => {
    setCommentText("");
    setOpenCommentDialog(true);
  };
  const handleCommentConfirm = async () => {
    try {
      if (commentText.trim() === "") {
        // コメントテキストが空の場合はコメントを追加しない
        return;
      }
      await addDoc(collection(db, "comments"), {
        historyId: id,
        text: commentText,
        createdAt: serverTimestamp(),
        uid: auth.currentUser.uid,
        displayName: auth.currentUser.displayName,
        photoURL: auth.currentUser.photoURL,
      });
      setCommentText("");
      setOpenCommentDialog(false);
    } catch (error) {
      console.error("Error adding comment: ", error);
    }
  };

  const handleCommentDeleteClick = (id: string) => {
    setDeleteCommentId(id);
    setCommentDeleteOpen(true);
  };

  const handleCommentDeleteConfirm = async () => {
    try {
      await deleteDoc(doc(db, "comments", deleteCommentId));
      setComments((prev) =>
        prev.filter((comment) => comment.id !== deleteCommentId)
      );
      setDeleteCommentId("");
      setCommentDeleteOpen(false);
    } catch (error) {
      console.error("コメントの削除エラー:", error);
    }
  };

  const handleCommentDeleteCancel = () => {
    setDeleteCommentId("");
    setCommentDeleteOpen(false);
  };

  const handleLikeClick = async (historyId: string) => {
    try {
      const historyRef = doc(db, "histories", historyId);
      const historyDoc = await getDoc(historyRef);
      if (historyDoc.exists()) {
        const historyData = historyDoc.data() as History;
        const likes = historyData.likes || []; // 'likes' フィールドがない場合は空の配列を返す
        if (!likes || !likes.includes(auth.currentUser.uid)) {
          const updatedLikes = [...(likes || []), auth.currentUser.uid]; // 'likes' フィールドがない場合は空の配列として初期化
          await updateDoc(historyRef, { likes: updatedLikes });
          setHistories((prev) =>
            prev.map((history) =>
              history.id === historyId
                ? { ...history, likes: updatedLikes }
                : history
            )
          );
        }
      }
    } catch (error) {
      console.error("Error updating likes:", error);
    }
  };

  const handleShareClick = (id: string) => {
    setSharedId(id);
    setSharedOpen(true);
  };

  const handleShareConfirm = async () => {
    try {
      if (selectedUsers.length === 0) {
        console.log("選択されていない");
        return;
      }

      // 選択されたユーザーに対して履歴を共有する処理を追加する
      // ここで selectedUsers 配列を使って処理を行う
      console.log(sharedUsers);

      setSharedId("");
      setSharedOpen(false);
    } catch (error) {
      console.error("Error add shares:", error);
    }
  };
  const [shredId, setSharedId] = useState("");
  const [sharedOpen, setSharedOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const handleShareCancel = () => {
    setSharedId("");
    setSharedOpen(false);
  };

  return (
    <Transition>
      <Box>
        <Button onClick={() => navigate(-1)} sx={{ fontSize: 18 }}>
          <KeyboardBackspaceIcon />
        </Button>

        <List>
          {histories.map((history) => (
            <Box sx={{ display: "flex" }} key={history.id}>
              <ListItemButton
                onClick={() => {
                  if (auth.currentUser.uid === history.uid) {
                    setOpenDialog(true);
                    setSelectedHistory(history);
                    setMemoText(history.memo || "");
                  }
                }}
                sx={{ flex: 1 }}
              >
                <ListItem alignItems="flex-start" divider>
                  <ListItemAvatar>
                    <Avatar alt="Profile Picture" src={history.photoURL} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <div style={{ fontSize: "1.1rem" }}>
                          {history.displayName}
                        </div>
                        <div style={{ fontSize: "1.1rem" }}>
                          乗車：{history.stationName}駅 →{" "}
                          {history.targetStationName}駅
                        </div>
                        <div style={{ marginTop: "4px" }}>
                          {history.memo
                            ? history.memo.split("\n").map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))
                            : null}
                        </div>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <span style={{ marginTop: "8px" }}>
                          {history.createdAt &&
                            history.createdAt.toDate().toLocaleString("ja-JP", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                        </span>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </ListItemButton>
            </Box>
          ))}
        </List>

        <Box>
          {histories.map((history) => (
            <Box
              key={history.id}
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                marginBottom: 0.3,
              }}
            >
              <IconButton onClick={() => handleCommentClick()}>
                <ModeCommentOutlinedIcon fontSize="medium" />
              </IconButton>
              <IconButton onClick={() => handleLikeClick(history.id)}>
                <ThumbUpIcon
                  fontSize="medium"
                  // color={
                  //   history.likes.includes(auth.currentUser.uid)
                  //     ? "primary"
                  //     : "inherit"
                  // }
                />
              </IconButton>
              {/* {history.uid === auth.currentUser.uid && (
                <IconButton onClick={() => handleShareClick(history.id)}>
                  <ShareIcon fontSize="medium" />
                </IconButton>
              )} */}
              {history.uid === auth.currentUser.uid && (
                <IconButton onClick={() => handleDeleteClick(history.id)}>
                  <DeleteIcon fontSize="medium" />
                </IconButton>
              )}
            </Box>
          ))}
        </Box>
        <Divider></Divider>

        <List>
          {comments.map((comment) => (
            <Box sx={{ display: "flex" }} key={comment.id}>
              <ListItem alignItems="flex-start" divider>
                <ListItemAvatar>
                  <Avatar alt="Profile Picture" src={comment.photoURL} />
                </ListItemAvatar>
                <ListItemText
                  primary={comment.displayName}
                  secondary={
                    <React.Fragment>
                      <span>
                        {comment.text
                          ? comment.text.split("\n").map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))
                          : null}
                      </span>
                      <span style={{ marginTop: "8px" }}>
                        {comment.createdAt &&
                          comment.createdAt.toDate().toLocaleString("ja-JP", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                      </span>
                    </React.Fragment>
                  }
                />
              </ListItem>
              {comment.uid === auth.currentUser.uid && (
                <IconButton
                  key={`delete-${comment.id}`}
                  aria-label="delete"
                  sx={{ width: "auto" }}
                  onClick={() => handleCommentDeleteClick(comment.id)}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          ))}
        </List>

        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          fullWidth
          maxWidth="sm"
          sx={{ "& .MuiDialog-paper": { width: "100%", maxWidth: "600px" } }}
        >
          <DialogTitle>旅のメモ</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="memo"
              label="メモを入力してください"
              type="text"
              fullWidth
              multiline
              rows={4}
              value={memoText}
              onChange={(e) => setMemoText(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>キャンセル</Button>
            <Button
              onClick={async () => {
                if (selectedHistory) {
                  try {
                    await updateMemo(selectedHistory.id, memoText);
                    setOpenDialog(false);
                  } catch (error) {
                    console.error("Error updating memo:", error);
                  }
                }
              }}
            >
              登録
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openCommentDialog}
          onClose={() => setOpenCommentDialog(false)}
          fullWidth
          maxWidth="sm"
          sx={{ "& .MuiDialog-paper": { width: "100%", maxWidth: "600px" } }}
        >
          <DialogTitle>コメント</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="memo"
              label="コメントを入力して下さい"
              type="text"
              fullWidth
              multiline
              rows={1}
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenCommentDialog(false)}>
              キャンセル
            </Button>
            <Button onClick={handleCommentConfirm}>コメント</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteOpen}
          onClose={handleDeleteCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">確認</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              この操作は取り消せません。
              <br />
              本当に削除しますか？
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              キャンセル
            </Button>
            <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
              削除
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={commentDeleteOpen}
          onClose={handleCommentDeleteCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">確認</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              この操作は取り消せません。
              <br />
              本当に削除しますか？
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCommentDeleteCancel} color="primary">
              キャンセル
            </Button>
            <Button
              onClick={handleCommentDeleteConfirm}
              color="primary"
              autoFocus
            >
              削除
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={sharedOpen}
          onClose={handleShareCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">共有</DialogTitle>
          <DialogContent>
            <Autocomplete
              multiple
              id="tags-standard"
              options={sharedUsers.map((user) => user.displayName)}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="履歴を追加するユーザーを選択"
                  placeholder="ユーザーを選択"
                />
              )}
              onChange={(event, value) => {
                // 選択されたユーザーの ID を抽出してセットする
                const selectedUserIds = value
                  .map(
                    (displayName) =>
                      sharedUsers.find(
                        (user) => user.displayName === displayName
                      )?.id
                  )
                  .filter((id) => id !== undefined) as string[];
                setSelectedUsers(selectedUserIds);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleShareCancel} color="primary">
              キャンセル
            </Button>
            <Button onClick={handleShareConfirm} color="primary" autoFocus>
              決定
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Transition>
  );
}
