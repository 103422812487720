import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { auth } from "../firebase";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { fetchSharedUsers } from "../data/firestore";
import { DeleteSharedUser } from "../data/firestore";
import Transition from "../utils/Transition";

type User = {
  id: string;
  displayName: string;
  uid: string;
  photoURL: string;
};

export default function UserList() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [sharedUsers, setSharedUsers] = useState<User[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const userList = await fetchSharedUsers(auth.currentUser.uid);
      setSharedUsers(userList);
    };

    fetchData();
  }, []);

  const handleDeleteConfirm = async () => {
    try {
      // ユーザー削除関数の実行
      DeleteSharedUser(deleteId);
      // 画面更新処理の実行
      const userList = await fetchSharedUsers(auth.currentUser.uid);
      setSharedUsers(userList);
    } catch (error) {
      console.error("Error deleting user:", error);
    } finally {
      setDeleteId("");
      setOpen(false);
    }
  };

  return (
    <Transition>
      <Box>
        <Button onClick={() => navigate(-1)} sx={{ fontSize: 18 }}>
          <KeyboardBackspaceIcon />
        </Button>

        <List>
          {sharedUsers.map((user) => (
            <ListItem key={user.id} divider>
              <ListItemAvatar>
                <Avatar alt="Profile Picture" src={user.photoURL} />
              </ListItemAvatar>
              <ListItemText primary={user.displayName} />
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  setDeleteId(user.id);
                  setOpen(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">確認</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              この操作は取り消せません。本当に削除しますか？
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              キャンセル
            </Button>
            <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
              削除
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Transition>
  );
}
