import * as React from "react";
import BottomBar from "../common/BottomBar";
import { Outlet } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "../common/AppBar";

const COOKIE_NAME = "darkMode";

export default function AppLayout() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [darkMode, setDarkMode] = React.useState(() => {
    const cookieDarkMode = document.cookie
      .split("; ")
      .find((row) => row.startsWith(`${COOKIE_NAME}=`))
      ?.split("=")[1];
    return cookieDarkMode ? cookieDarkMode === "true" : prefersDarkMode;
  });

  const handleChange = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    document.cookie = `${COOKIE_NAME}=${newDarkMode}; max-age=31536000;`;
  };

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? "dark" : "light",
          secondary: {
            main: darkMode ? "#757575" : "#448aff",
          },
        },
      }),
    [darkMode]
  );

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* アップバー */}
        <AppBar darkMode={darkMode} onChange={handleChange} />
        {/* メインコンテンツ */}
        <Outlet />
        {/* ボトムバー */}
        <BottomBar />
      </ThemeProvider>
    </>
  );
}
