import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import {
  auth,
  collection,
  db,
  getDocs,
  query as firebaseQuery,
  where,
} from "../firebase";
import { Timestamp, doc, getDoc, updateDoc } from "firebase/firestore";
import Transition from "../utils/Transition";

type addUser = {
  id: string;
  displayName: string;
  createdAt: Timestamp;
  uid: string;
  photoURL: string;
  sharedUsers: string[];
};

type User = {
  id: string;
  displayName: string;
  createdAt: Timestamp;
  uid: string;
  photoURL: string;
  sharedUsers: string[];
};

export default function Shared() {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const url = query.get("url");
  const [userName, setUserName] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [uid, setuid] = useState("");

  const navigate = useNavigate();

  const fetchUserData = async (docId: string) => {
    try {
      const docRef = doc(db, "users", docId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const userData = {
          id: docSnapshot.id,
          ...docSnapshot.data(),
        } as addUser;
        setUserName(userData.displayName);
        setPhotoUrl(userData.photoURL);
        setuid(userData.uid);
      } else {
        navigate("/home");
      }
    } catch (error) {
      console.error("ヒストリーの取得中にエラーが発生しました:", error);
    }
  };

  const updateSharedUser = async (docId: string) => {
    try {
      // 相手のusersコレクションに登録する
      const addUserRef = doc(db, "users", docId);
      const userDoc = await getDoc(addUserRef);
      if (userDoc.exists()) {
        const sharedUsers = userDoc.data().sharedUsers || [];
        await updateDoc(addUserRef, {
          sharedUsers: [...sharedUsers, auth.currentUser.uid], // 新しい配列に追加
        });
      }

      // 自分のusersコレクションに登録する
      const userRef = collection(db, "users");
      let a = firebaseQuery(userRef, where("uid", "==", auth.currentUser.uid));
      const querySnapshot = await getDocs(a);
      const userData: User[] = [];
      querySnapshot.forEach((doc) => {
        userData.push({ id: doc.id, ...doc.data() } as User);
      });
      await updateDoc(doc(db, "users", userData[0].id), {
        sharedUsers: [uid],
      });
    } catch (error) {
      console.error("Error adding users:", error);
    }
  };

  useEffect(() => {
    if (!url) {
      navigate("/home");
    } else {
      fetchUserData(url);
    }
  }, [url, navigate]);

  const handleExecute = async () => {
    updateSharedUser(url);
    navigate("/home");
  };

  return (
    <Transition>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "50px auto",
          width: "75%",
          padding: "0 16px",
          marginTop: "30px",
          height: "50vh",
          textAlign: "center",
        }}
      >
        {url && uid == auth.currentUser.uid ? (
          <Box
            sx={{
              width: "100%",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              <img src={photoUrl} style={{ borderRadius: 50 }} />
            </Typography>
            <Typography variant="h5" component="div">
              {userName}
            </Typography>
            <Typography>自分自身は追加できません</Typography>
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              <img src={photoUrl} style={{ borderRadius: 50 }} />
            </Typography>
            <Typography variant="h5" component="div">
              {userName}
            </Typography>
            <Button
              variant="contained"
              onClick={handleExecute}
              sx={{
                color: "white",
                width: "100%",
                height: 50,
                fontSize: 18,
                borderRadius: 10,
                marginTop: 2,
              }}
            >
              登録する
            </Button>
          </Box>
        )}
      </Box>
    </Transition>
  );
}
