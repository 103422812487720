export const stations = [
    { value: 1, label: '東京', kana: 'とうきょう' },
    { value: 2, label: '神田', kana: 'かんだ' },
    { value: 3, label: '御茶ノ水', kana: 'おちゃのみず' },
    { value: 4, label: '四ツ谷', kana: 'よつや' },
    { value: 5, label: '新宿', kana: 'しんじゅく' },
    { value: 6, label: '中野', kana: 'なかの' },
    { value: 7, label: '高円寺', kana: 'こうえんじ' },
    { value: 8, label: '阿佐ヶ谷', kana: 'あさがや' },
    { value: 9, label: '荻窪', kana: 'おぎくぼ' },
    { value: 10, label: '西荻窪', kana: 'にしおぎくぼ' },
    { value: 11, label: '吉祥寺', kana: 'きちじょうじ' },
    { value: 12, label: '三鷹', kana: 'みたか' },
    { value: 13, label: '武蔵境', kana: 'むさしさかい' },
    { value: 14, label: '東小金井', kana: 'ひがしこがねい' },
    { value: 15, label: '武蔵小金井', kana: 'むさしこがねい' },
    { value: 16, label: '国分寺', kana: 'こくぶんじ' },
    { value: 17, label: '西国分寺', kana: 'にしこくぶんじ' },
    { value: 18, label: '国立', kana: 'くにたち' },
    { value: 19, label: '立川', kana: 'たちかわ' },
    { value: 20, label: '日野', kana: 'ひの' },
    { value: 21, label: '豊田', kana: 'とよだ' },
    { value: 22, label: '八王子', kana: 'はちおうじ' },
    { value: 23, label: '西八王子', kana: 'にしはちおうじ' },
    { value: 24, label: '高尾', kana: 'たかお' },
    { value: 25, label: '相模湖', kana: 'さがみこ' },
    { value: 26, label: '藤野', kana: 'ふじの' },
    { value: 27, label: '上野原', kana: 'うえのはら' },
    { value: 28, label: '四方津', kana: 'しおつ' },
    { value: 29, label: '梁川', kana: '' },
    { value: 30, label: '鳥沢', kana: 'とりさわ' },
    { value: 31, label: '猿橋', kana: 'さるはし' },
    { value: 32, label: '大月', kana: 'おおつき' },
    { value: 33, label: '初狩', kana: 'はつかり' },
    { value: 34, label: '笹子', kana: 'ささご' },
    { value: 35, label: '甲斐大和', kana: 'かいすみよし' },
    { value: 36, label: '勝沼ぶどう郷', kana: 'かつぬまぶどうきょう' },
    { value: 37, label: '塩山', kana: 'えんざん' },
    { value: 38, label: '東山梨', kana: 'ひがしやまなし' },
    { value: 39, label: '山梨市', kana: 'やまなしし' },
    { value: 40, label: '春日居町', kana: 'かすがい' },
    { value: 41, label: '石和温泉', kana: 'いさわおんせん' },
    { value: 42, label: '酒折', kana: 'さかおり' },
    { value: 43, label: '甲府', kana: 'こうふ' },
    { value: 44, label: '竜王', kana: 'りゅうおう' },
    { value: 45, label: '塩崎', kana: 'しおざき' },
    { value: 46, label: '韮崎', kana: 'にらさき' },
    { value: 47, label: '新府', kana: 'しんぷ' },
    { value: 48, label: '穴山', kana: 'あなやま' },
    { value: 49, label: '日野春', kana: 'ひのはる' },
    { value: 50, label: '長坂', kana: 'ながさか' },
    { value: 51, label: '小淵沢', kana: 'こぶちざわ' },
    { value: 52, label: '信濃境', kana: '' },
    { value: 53, label: '富士見', kana: 'ふじみ' },
    { value: 54, label: 'すずらんの里', kana: 'すずらんのさと' },
    { value: 55, label: '青柳', kana: '' },
    { value: 56, label: '茅野', kana: 'ちの' },
    { value: 57, label: '上諏訪', kana: 'かみすわ' },
    { value: 58, label: '下諏訪', kana: 'しもすわ' },
    { value: 59, label: '岡谷', kana: 'おかや' },
    { value: 60, label: 'みどり湖', kana: '' },
    { value: 61, label: '塩尻', kana: 'しおじり' },
    { value: 62, label: '広丘', kana: '' },
    { value: 63, label: '村井', kana: '' },
    { value: 64, label: '平田', kana: '' },
    { value: 65, label: '南松本', kana: 'みなみまつもと' },
    { value: 66, label: '松本', kana: 'まつもと' },
];