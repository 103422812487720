import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Box, Container, IconButton } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

interface Props {
  window?: () => Window;
  children?: React.ReactElement;
  darkMode: boolean;
  onChange: () => void;
}

function ElevationScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default function ElevateAppBar(props: Props) {
  const { darkMode, onChange } = props;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" color="secondary">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1 }} />
            <Typography
              variant="h5"
              noWrap
              component="div"
              sx={{
                textAlign: "center", // 中央寄せ
                width: "100%", // 幅いっぱいに広げる
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
                marginRight: -5, // ここを追加
              }}
            >
              鉄道ぶらり旅
            </Typography>
            <Box sx={{ ml: "auto" }} /> {/* 右寄せのためのスペース */}
            <IconButton onClick={onChange}>
              {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
      <Box component="main" sx={{ p: 1 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}
